
.footer {
    background: #f8f9fa!important;
    margin-top: 20px;
    padding: 5px;
}

.footer .navbar-text {
    /*color: #fff;*/
    text-align: left;
}