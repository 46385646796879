.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* ----------------------- */

.jumbotron {
    background: rgb(238, 174, 202);
    background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
    padding: 2rem 2rem !important;
}

.gradientText {
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*for button: cursor: pointer !important;*/
.mathInput {
    /*width: 60px !important;*/
    cursor: auto !important;

}

/* Ensure that we have inline in small devices also! (https://react-bootstrap.netlify.app/components/forms/#forms-inline) */
@media (max-width: 576px) {
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
}

/*@media (max-width: 576px) {*/
/*    .row {*/
/*        !*max-width: 500px;*!*/
/*        !*margin: 1.75rem auto;*!*/
/*        background-color: green !important;*/
/*    }*/

/*    .mathInput {*/
/*        width: 60px !important;*/
/*        cursor: pointer !important;*/
/*    }*/
/*}*/